<template>
  <v-navigation-drawer
    :width="collapseWidth * 1.5"
    right
    class="above-overlay"
    v-model="myConnectSidebar"
    :clipped="true"
    :floating="true"
    :permanent="false"
    :temporary="true"
    app
    overflow
  >
    <template v-slot:prepend>
      <v-list-item class="primary">
        <v-list-item-icon>
          <v-icon @click="close" color="white" large>
            mdi-close-circle-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="title light--text">
            Integrations
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon large color="white"> mdi-puzzle </v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>
    </template>
    <v-list>
      <v-hover v-slot="{ hover }" v-for="item in connectedApps" :key="item.id">
        <v-list-item @click.stop="redirectTo(item)">
          <v-list-item-avatar tile width="50">
            <v-img :lazy-src="settings.loader" :src="item.logo" contain></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="subtitle-2 primary--text">
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption" v-if="item.available">
              <span :class="[{ 'success--text': item.connected }]">
                Integration is available
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="caption" v-else>
              <span> Coming soon ... </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon v-if="item.available">
            <v-icon :color="hover ? 'primary' : ''">mdi-cog</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-hover>
    </v-list>
    <template v-slot:append>
      <div class="fullwidth primary py-2 d-flex justify-center">
        <v-btn
          text
          depressed
          class="light--text text-none"
          @click="$router.push({ name: 'connect' })"
        >
          <v-icon color="white" left>mdi-toy-brick-search</v-icon> See More
          Integrations
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  name: 'ConnectSidebar',
  computed: {
    ...mapGetters(['user', 'user_roles']),
    ...mapGetters('app', [
      'collapseWidth',
      'connectSidebar',
      'connects',
      'allowedConnects'
    ]),
    myConnectSidebar: {
      get() {
        return this.connectSidebar
      },
      set(val) {
        this.$store.commit('app/set_connectSidebar', val)
      }
    },
    connectedApps() {
      return _.cloneDeep(this.connects)
        .map((i) => {
          i.available = this.allowedConnects.includes(i.id)
          return i
        })
        .sort((a, b) => b.available - a.available)
    }
  },
  methods: {
    close() {
      this.$store.commit('app/set_connectSidebar', false)
    },
    redirectTo(item) {
      if (!item.id) {
        return
      }
      this.$store.commit('app/set_activeSidebar', item)
      this.$store.commit('app/set_connectSidebar', false)
      if (item.available) {
        if (
          [
            'mailgun',
            'mailchimp',
            'mailtrap',
            'postmark',
            'sendgrid',
            'smtp2go'
          ].includes(item.id)
        ) {
          this.$router
            .push({
              name: `connect-mailers`,
              params: { mailer: item.id },
              query: { redirect_uri: this.$route.fullPath }
            })
            .catch(() => {})
        } else {
          this.$router
            .push({
              name: `connect-${item.id}`,
              query: { redirect_uri: this.$route.fullPath }
            })
            .catch(() => {})
        }
      } else
        this.$router
          .push({
            name: 'connect',
            query: { redirect_uri: this.$route.fullPath }
          })
          .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped></style>
