<template>
  <v-navigation-drawer
    :width="collapseWidth * 1.2"
    right
    class="above-overlay"
    v-model="rightSidebar"
    :clipped="true"
    :floating="true"
    :permanent="false"
    :temporary="true"
    app
    overflow
  >
    <template v-slot:prepend>
      <v-list-item class="primary">
        <v-list-item-avatar>
          <img :src="user.image_url" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="subtitle-2 light--text">
            {{ user.fullname }}
          </v-list-item-title>
          <v-list-item-subtitle class="caption light--text">
            {{ user.job_title }}
          </v-list-item-subtitle>
          <v-list-item-subtitle class="caption light--text">
            {{ user.company.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card
        tile
        flat
        v-if="(user.is_admin || user.manager) && !user.company.is_exempted"
        height="120"
        dense
        no-gutters
        color="primary"
      >
        <v-card-text v-if="!user.company.either_subscribed_or_exempted">
          <h3
            class="light--text mb-1 text-center"
            v-if="user.company.trial_days_left > 0"
          >
            You have {{ user.company.trial_days_left }} days left in your free
            trial
          </h3>
          <h3 class="red--text mb-1 text-center" v-else>
            Your free trial is over! Subscribe to continue
          </h3>
          <v-btn
            @click="goSubscribe"
            color="success"
            block
            depressed
            class="fs-15 text-none"
          >
            Subscribe to Premium
          </v-btn>
        </v-card-text>
      </v-card>
      <v-divider></v-divider>
    </template>
    <template v-slot:append>
      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <v-btn
            block
            class="text-none"
            dark
            @click.stop="close"
            :loading="btnloading"
            color="primary"
          >
            <v-icon left>mdi-close-circle-outline</v-icon> Close
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-list>
      <template v-for="(item, index) in items">
        <v-list-item
          :value="item"
          v-if="
            (!item.children || item.children.length < 1) &&
            inModuleOrDefault(item)
          "
          :key="`main-${index}`"
          :class="isActiveSidebar(item) ? 'primary' : 'white'"
          @click.stop="action(item)"
        >
          <v-list-item-icon>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="isActiveSidebar(item) ? '#fff' : 'primary'"
                  v-if="item.icon_type === 'mdi'"
                >
                  {{ item.icon_name }}
                </v-icon>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold"
              :class="isActiveSidebar(item) ? 'white--text' : 'primary--text'"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-else-if="inModuleOrDefault(item)"
          :key="`main-${index}`"
          @click.stop="expandSubItemOnCollapsedSidebar"
        >
          <v-tooltip right slot="prependIcon">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                color="primary"
                v-if="item.icon_type === 'mdi'"
              >
                {{ item.icon_name }}
              </v-icon>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                :class="isActiveSidebar(item) ? 'white--text' : 'primary--text'"
              >
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(subitem, index2) in item.children">
            <v-list-item
              :value="subitem"
              v-if="inModuleOrDefault(subitem)"
              :key="`sub-${index2}`"
              @click.stop="action(subitem)"
              class="sub-group pl-4"
              :class="isActiveSidebar(subitem) ? 'primary' : 'white'"
            >
              <v-list-item-icon>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      :color="isActiveSidebar(subitem) ? '#fff' : 'primary'"
                      v-if="subitem.icon_type === 'mdi'"
                    >
                      {{ subitem.icon_name }}
                    </v-icon>
                  </template>
                  <span>{{ subitem.title }}</span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :class="
                    isActiveSidebar(subitem) ? 'white--text' : 'primary--text'
                  "
                  v-text="subitem.title"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import _ from 'lodash'
export default {
  name: 'RightSidebar',
  data: () => ({
    btnloading: false
  }),
  computed: {
    ...mapGetters(['user', 'user_roles']),
    ...mapGetters('app', ['collapseWidth', 'allowedModules']),
    items() {
      return this.$store.getters['app/userNavigations'](this.user_roles)
    },
    rightSidebar: {
      get() {
        return this.$store.getters['app/rightSidebar']
      },
      set(val) {
        this.$store.commit('app/set_rightSidebar', val)
      }
    }
  },
  created() {
    this.$store.dispatch(
      'configs/fetchModulesAndSubscriptions',
      this.user.company.id
    )
  },
  methods: {
    inModuleOrDefault(item) {
      if (!item.module && item.children && item.children.length) {
        return item.children.filter((i) => this.inModuleOrDefault(i)).length
      }
      return this.allowedModules.includes(item.module) || !item.module
    },
    close() {
      this.$store.commit('app/set_rightSidebar', false)
    },
    isActiveSidebar(item) {
      if (!item.route) {
        return false
      }
      return (
        this.$route.name === item.route.name &&
        _.isEqual(this.$route.params, item.route.params)
      )
    },
    action(item) {
      let new_item = _.cloneDeep(item)
      if (new_item.title === 'Profile') {
        new_item.route.params.username = this.user.username
        this.$store.commit('memberProfile/set_username', this.user.username)
      } else if (new_item.title === 'Logout') {
        this.$event.$emit('user-logout')
        return
      }
      this.$store.commit('app/set_activeSidebar', new_item)
      this.$store.commit('app/set_rightSidebar', false)
      this.$router.push(new_item.route).catch(() => {})
    },
    expandSubItemOnCollapsedSidebar() {
      this.$emit('expand-sub-item-on-collapsed-sidebar', true)
    },
    goSubscribe() {
      this.$router.push({ name: 'subscriptions' }).catch(() => {})
      this.$store.commit('app/set_rightSidebar', false)
    }
  }
}
</script>

<style lang="css" scoped>
.v-navigation-drawer.v-navigation-drawer--mini-variant {
  width: 70px;
}
.v-navigation-drawer .sub-group {
  background-color: #80808029;
}
</style>
