<template>
  <v-tooltip bottom color="primary" v-if="canConnect">
    <template v-slot:activator="{ on: tooltip }">
      <v-hover v-slot="{ hover }">
        <v-btn
          width="40"
          tile
          text
          icon
          v-on="{ ...tooltip }"
          @click.stop="toggleConnectSidebar"
        >
          <v-icon :color="hover ? 'success' : 'primary'">
            mdi-puzzle-plus
          </v-icon>
        </v-btn>
      </v-hover>
    </template>
    <span>Integrations</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'HeaderConnects',
  computed: {
    canConnect() {
      return this.$route.name !== 'connect'
    }
  },
  methods: {
    toggleConnectSidebar() {
      this.$store.commit('app/set_connectSidebar', true)
    }
  }
}
</script>

<style></style>
