var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{staticClass:"left-sidebar above-overlay",staticStyle:{"margin-top":"-1px"},attrs:{"width":_vm.collapseWidth,"clipped":_vm.smAndDown ? true : _vm.clipped,"floating":_vm.floating,"mini-variant":_vm.mini,"mini-variant-width":_vm.miniWidth,"permanent":_vm.smAndDown ? false : _vm.type === 'permanent',"temporary":_vm.smAndDown ? true : _vm.type === 'temporary',"app":"","fixed":"","stateless":!_vm.smAndDown},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-list-item',[_c('v-list-item-avatar',{staticStyle:{"border":"2px solid #223a6b"},attrs:{"size":"35"}},[(_vm.user.company.company_logo)?_c('v-img',{attrs:{"contain":"","src":_vm.user.company.company_logo}}):_c('v-img',{attrs:{"lazy-src":_vm.settings.loader,"contain":"","src":_vm.settings.noCompanyLogo}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm.user.company.name)+" ")])],1)],1)]},proxy:true}]),model:{value:(_vm.leftSidebar),callback:function ($$v) {_vm.leftSidebar=$$v},expression:"leftSidebar"}},[_c('v-list',{attrs:{"dense":"","transition":"slide-x-transition"}},[_vm._l((_vm.sidebars_filtered),function(item,index){return [(
          (!item.children || item.children.length < 1) &&
          _vm.inModuleOrDefault(item)
        )?_c('v-list-item',{key:("main-" + index),staticClass:"main-group",class:_vm.isActive(item) ? 'primary' : 'white',attrs:{"value":item},on:{"click":function($event){$event.stopPropagation();return _vm.action(item)}}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"color":"primary","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(item.icon_type === 'mdi')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.isActive(item) ? '#fff' : 'primary'}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.icon_name)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.isActive(item) ? 'white--text' : 'primary--text'},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1):(_vm.inModuleOrDefault(item))?_c('v-list-group',{key:("main-" + index),attrs:{"no-action":""},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('v-tooltip',{attrs:{"color":"primary","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"bordered":"","bottom":"","dot":"","color":"primary","offset-x":"5","offset-y":"10"}},[(item.icon_type === 'mdi')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary "}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.icon_name)+" ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(item.title)+" ")])],1)]},proxy:true}],null,true)},[_vm._l((item.children),function(subitem,index2){return [(_vm.inModuleOrDefault(subitem))?_c('v-list-item',{key:("sub-" + index2),staticClass:"sub-group pl-4",class:_vm.isActive(subitem) ? 'primary' : 'white',attrs:{"value":subitem},on:{"click":function($event){$event.stopPropagation();return _vm.action(subitem)}}},[_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"color":"primary","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(subitem.icon_type === 'mdi')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.isActive(subitem) ? '#fff' : 'primary'}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(subitem.icon_name)+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(subitem.title))])])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:_vm.isActive(subitem) ? 'white--text' : 'primary--text',domProps:{"textContent":_vm._s(subitem.title)}})],1)],1):_vm._e()]})],2):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }