<template>
  <v-tooltip color="primary" bottom v-if="canTimer">
    <template v-slot:activator="{ on: tooltip }">
      <v-badge
        v-on="{ ...tooltip }"
        :color="timer_color"
        overlap
        dot
        offset-x="15"
        offset-y="15"
      >
        <v-hover v-slot="{ hover }">
          <v-btn
            width="40"
            tile
            text
            :disabled="globalTimerLoading"
            @click="toggleTimer"
            icon
            v-on="{ ...tooltip }"
          >
            <v-icon :color="hover ? 'success' : 'primary'">
              {{ timer_spin_icon }}
            </v-icon>
          </v-btn>
        </v-hover>
      </v-badge>
    </template>
    <span>{{ tooltip_message }}</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'HeaderMessages',
  computed: {
    ...mapGetters(['user', 'user_has_role']),
    ...mapGetters('timers', [
      'globalTimerIsRunning',
      'globalTimerLoading',
      'globalTimerRequesting'
    ]),
    canTimer() {
      return this.user.is_client
        ? false
        : this.$_permissions.can('timers_own.create')
    },
    tooltip_message() {
      return this.globalTimerIsRunning ? 'Timer is running' : 'Timer is stopped'
    },
    timer_icon() {
      return this.globalTimerIsRunning ? 'mdi-spin mdi-loading' : 'mdi-play'
    },
    timer_color() {
      return this.globalTimerIsRunning ? 'success' : 'error'
    },
    timer_spin_icon() {
      return this.globalTimerIsRunning
        ? 'mdi-radius mdi-spin'
        : 'mdi-timer-outline'
    }
  },
  created() {
    this.getUserTimerStats()
  },
  methods: {
    ...mapActions('timers', ['getUserTimerStats', 'toggleGlobalTimer']),
    toggleTimer() {
      if (this.globalTimerRequesting) {
        return
      }
      this.toggleGlobalTimer({
        cb: () => {
          const timer_message = this.globalTimerIsRunning
            ? 'started'
            : 'stopped'
          this.$event.$emit('timer-status', this.globalTimerIsRunning)
          this.$event.$emit(
            this.globalTimerIsRunning
              ? 'global-timer-started'
              : 'global-timer-stopped'
          )
          this.appSnackbar(`Timer ${timer_message}`, 'success')
        },
        err_cb: undefined
      })
    }
  }
}
</script>

<style></style>
