<template>
  <v-tooltip color="primary" bottom v-if="canDashcard">
    <template v-slot:activator="{ on: tooltip }">
      <v-hover v-slot="{ hover }">
        <v-btn
          width="40"
          tile
          text
          icon
          v-on="{ ...tooltip }"
          @click.stop="toggleCardSidebar"
        >
          <v-icon :color="hover ? 'success' : 'primary'">
            mdi-view-grid-plus
          </v-icon>
        </v-btn>
      </v-hover>
    </template>
    <span>Dashcards</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'HeaderDashcards',
  computed: {
    canDashcard() {
      return this.$route.name === 'default-content' ? true : false
    }
  },
  methods: {
    toggleCardSidebar() {
      this.$store.commit('app/set_cardSidebar', true)
    }
  }
}
</script>

<style></style>
