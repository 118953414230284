<template>
  <v-app-bar
    :clipped-left="clipped"
    class="white"
    app
    tile
    flat
    style="border-botton: 1px solid grey !important"
  >
    <v-toolbar-title class="cursor-pointer" @click="home">
      <v-avatar
        tile
        height="50"
        width="200"
        v-show="!mini && mdAndUp"
        class="mr-5"
      >
        <v-img :src="settings.longLogo"></v-img>
      </v-avatar>
      <v-avatar
        tile
        height="50"
        width="50"
        v-show="mini || smAndDown"
        class="mr-5"
      >
        <v-img :src="settings.squareLogo"></v-img>
      </v-avatar>
    </v-toolbar-title>
    <v-app-bar-nav-icon
      color="primary"
      v-if="type !== 'permanent'"
      @click.stop="toggleMini"
    ></v-app-bar-nav-icon>
    <GlobalSearch v-if="mdAndUp"></GlobalSearch>
    <v-spacer></v-spacer>
    <GlobalTimer class="order-1"></GlobalTimer>
    <Connects class="order-2" v-show="mdAndUp"></Connects>
    <Dashcards class="order-3" v-show="mdAndUp"></Dashcards>
    <HeaderMessages class="order-4"></HeaderMessages>
    <HeaderNotifications class="order-5"></HeaderNotifications>
    <div class="d-flex" @click.stop="toggleRightSidebar">
      <v-hover v-slot="{ hover }" class="order-6">
        <v-badge
          avatar
          bordered
          icon="mdi-chevron-double-left"
          offset-y="42"
          :color="hover ? 'success' : 'primary'"
          class="cursor-pointer"
          offset-x="8"
        >
          <v-avatar size="40" class="ml-2" @click.stop="toggleRightSidebar">
            <v-img
              :lazy-src="settings.loader"
              contain
              :src="user.image_url"
            ></v-img>
          </v-avatar>
        </v-badge>
      </v-hover>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderNotifications from './HeaderComponents/Notifications.vue'
import HeaderMessages from './HeaderComponents/Messages.vue'
import GlobalTimer from './HeaderComponents/GlobalTimer.vue'
import Connects from './HeaderComponents/Connects.vue'
import Dashcards from './HeaderComponents/Dashcards.vue'
import GlobalSearch from './HeaderComponents/GlobalSearch.vue'
export default {
  name: 'MainHeader',
  components: {
    HeaderNotifications,
    HeaderMessages,
    GlobalTimer,
    Connects,
    Dashcards,
    GlobalSearch
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('app', ['type']),
    can_settings() {
      if (this.user.is_client) return false
      if (this.user.is_admin) return true
      return this.$_permissions.can('settings_permission.view')
    },
    rightSidebar: {
      get() {
        return this.$store.getters['app/rightSidebar']
      },
      set(val) {
        this.$store.commit('app/set_rightSidebar', val)
      }
    },
    clipped: {
      get() {
        return this.$store.getters['app/clipped']
      },
      set(val) {
        this.$store.commit('app/set_clipped', val)
      }
    },
    mini: {
      get() {
        return this.$store.getters['app/mini']
      },
      set(val) {
        this.$store.commit('app/set_mini', val)
      }
    }
  },
  methods: {
    setting() {
      this.$router.push('/dashboard/settings').catch(() => {})
    },
    home() {
      this.$router.push({ name: 'default-content' }).catch(() => {})
    },
    toggleMini() {
      this.$store.commit('app/set_mini', !this.mini)
      this.$store.commit('app/set_leftSidebar', true)
    },
    toggleRightSidebar() {
      this.$store.commit('app/set_rightSidebar', !this.rightSidebar)
    }
  }
}
</script>

<style lang="css" scoped>
header.white.v-sheet.theme--light.rounded-0.v-toolbar.v-toolbar--flat.v-app-bar.v-app-bar--clipped.v-app-bar--fixed::after {
  border: 1px solid #e0e0e0;
  content: '';
  display: block;
  width: 100%;
}
</style>
