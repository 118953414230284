<template>
  <v-navigation-drawer
    :width="collapseWidth * 1.5"
    right
    class="above-overlay"
    v-model="myCardSidebar"
    :clipped="true"
    :floating="true"
    :permanent="false"
    :temporary="true"
    app
    overflow
  >
    <template v-slot:prepend>
      <v-list-item class="primary">
        <v-list-item-icon>
          <v-icon @click="close" color="white" large>
            mdi-close-circle-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="title light--text">
            Dashcards
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon large color="white"> mdi-view-grid-plus </v-icon>
        </v-list-item-icon>
      </v-list-item>
      <div class="fullwidth primary pb-4 d-flex justify-center">
        <v-checkbox
          v-model="add_all_cards"
          off-icon="mdi-checkbox-blank-circle-outline"
          on-icon="mdi-circle-slice-8"
          hide-details="auto"
          dark
          label="Add all cards"
        >
        </v-checkbox>
      </div>
      <v-divider></v-divider>
    </template>
    <template v-slot:append>
      <v-list-item>
        <v-list-item-content>
          <v-btn
            block
            depressed
            class="text-none"
            :loading="btnloading"
            color="primary"
            height="45px"
            @click.stop="saveMyCards"
          >
            Save
          </v-btn>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>

    <v-list dense v-if="items.length">
      <v-list-item v-for="(card, index) in items" :key="index">
        <v-list-item-content class="pa-1">
          <v-card
            @click="toggleSelected(card)"
            elevation="5"
            active-class="white"
            class="fullwidth rounded-10"
          >
            <v-card-title>
              <strong class="fs-26 primary--text">{{ card.title }}</strong>
              <v-spacer />
              <v-checkbox
                :input-value="card.selected"
                off-icon="mdi-checkbox-blank-circle-outline"
                on-icon="mdi-circle-slice-8"
                class="card-add-box"
                :true-value="true"
                :false-value="false"
              ></v-checkbox>
            </v-card-title>
            <v-card-text class="d-flex justify-center">
              <v-avatar tile size="100%">
                <v-img
                  :lazy-src="settings.loader"
                  width="140"
                  contain
                  height="140"
                  :src="card.image"
                ></v-img>
              </v-avatar>
            </v-card-text>
          </v-card>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Dashcard from '@/modules/DashboardContent/components/Dashcard.vue'
import _ from 'lodash'
export default {
  name: 'CardSidebar',
  components: {
    Dashcard
  },
  data: () => ({
    btnloading: false,
    add_all_cards: false,
    my_dashcards: []
  }),
  created() {
    this.$store.watch(
      (state) => {
        return this.$store.getters['app/cardSidebar']
      },
      (val) => {
        if (val) {
          this.$store.commit('dashitems/init_forSelection', this.user_roles)
        }
      },
      {
        deep: true
      }
    )
  },
  watch: {
    add_all_cards(val) {
      this.$store.commit('dashitems/toggle_allCardSelected', val)
    }
  },
  computed: {
    ...mapGetters(['user', 'user_roles']),
    ...mapGetters('app', ['collapseWidth', 'cardSidebar']),
    ...mapGetters('dashitems', { items: 'cardsForSelection' }),
    myCardSidebar: {
      get() {
        return this.cardSidebar
      },
      set(val) {
        this.btnloading = false
        this.$store.commit('app/set_cardSidebar', val)
      }
    },
    selected() {
      return this.items.filter((i) => i.selected)
    }
  },
  methods: {
    ...mapMutations('dashitems', ['init_forSelection']),
    close() {
      this.$store.commit('app/set_connectSidebar', false)
    },
    toggleSelected(card) {
      this.$store.commit('dashitems/toggle_cardSelected', card.slug)
    },
    saveMyCards() {
      this.btnloading = true
      this.$store.commit('dashitems/save_userCards', this.selected)
      this.$nextTick(() => {
        this.btnloading = false
        this.close()
        this.init_forSelection(this.user_roles)
      })
    }
  }
}
</script>

<style lang="css" scoped>
.v-navigation-drawer.v-navigation-drawer--mini-variant {
  width: 70px;
}
.v-navigation-drawer .sub-group {
  background-color: #80808029;
}
</style>
<style lang="scss">
.card-add-box {
  .v-input__slot {
    .v-icon {
      font-size: 35px;
    }
  }
}
</style>
