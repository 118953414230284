<template>
  <v-navigation-drawer
    :width="collapseWidth * 1.5"
    right
    class="above-overlay"
    v-model="myNotificationSidebar"
    :clipped="true"
    :floating="true"
    :permanent="false"
    :temporary="true"
    app
    overflow
  >
    <template v-slot:prepend>
      <v-list-item class="primary">
        <v-list-item-icon>
          <v-icon @click="close" color="white" large>
            mdi-close-circle-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="title light--text">
            Notifications
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon large color="white"> mdi-bell-ring </v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>
    </template>
    <v-list dense v-if="notification.length">
      <v-list-item
        :class="{ unread: !notif.read_at }"
        v-for="(notif, index) of notification"
        :key="notif.id"
        @click="notificationClicked(notif, index)"
      >
        <v-list-item-avatar tile>
          <v-img
            :lazy-src="settings.noImagePreview"
            v-if="notif.data.image_url"
            :src="notif.data.image_url"
          />
          <v-icon v-else large>mdi-image-size-select-actual</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="primary--text">
            {{ notif.data.title | ucwords }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <small class="caption">{{ notif.created_at | from_now }}</small>
          </v-list-item-subtitle>
          <v-list-item-subtitle :title="notif.data.message">
            {{ notif.data.message }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-btn
            v-if="has_more_notification"
            small
            block
            dark
            :loading="isFetching"
            @click.stop="fetch_more_notification"
            color="primary"
            class="text-none light--text"
          >
            Load more
          </v-btn>
          <v-btn v-else small block disabled class="text-none primary--text">
            No more
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <Empty
      v-if="!notification.length && !isFetching"
      headline="Your notification will appear here"
      icon="mdi-bell-off-outline"
    ></Empty>
    <template v-if="isFetching">
      <v-skeleton-loader
        v-for="item in 5"
        :key="item"
        :boilerplate="true"
        type="list-item-avatar-three-line"
        :tile="false"
        class="mx-auto"
      />
    </template>
    <template v-slot:append>
      <v-divider></v-divider>
      <v-row dense style="height: 40px" class="px-3 mt-3">
        <v-btn
          class="text-none caption"
          dark
          small
          depressed
          @click.stop="close"
          color="primary"
        >
          <v-icon left>mdi-close-circle-outline</v-icon> Close
        </v-btn>
        <v-spacer />
        <v-btn
          class="text-none caption"
          dark
          small
          depressed
          @click.stop="markAll"
          :loading="isSubmitting"
          color="primary"
        >
          <v-icon left>mdi-book-open-variant</v-icon> Mark All as Read
        </v-btn>
      </v-row>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  name: 'NotificationSidebar',
  data: () => ({
    btnloading: false
  }),
  created() {
    this.$store.watch(
      (state) => {
        return this.$store.getters['app/notificationSidebar']
      },
      (val) => {
        if (val) {
          this.$store.dispatch('chatNotifications/fetch_notification')
        }
      },
      {
        deep: true
      }
    )
  },
  computed: {
    ...mapGetters(['user', 'user_roles']),
    ...mapGetters('app', ['collapseWidth', 'notificationSidebar']),
    ...mapGetters('chatNotifications', [
      'isFetching',
      'isSubmitting',
      'notification',
      'has_more_notification'
    ]),
    myNotificationSidebar: {
      get() {
        return this.notificationSidebar
      },
      set(val) {
        this.btnloading = false
        this.$store.commit('app/set_notificationSidebar', val)
      }
    }
  },
  methods: {
    ...mapActions('chatNotifications', [
      'fetch_more_notification',
      'mark_all_as_read'
    ]),
    close() {
      this.$store.commit('app/set_notificationSidebar', false)
    },
    notificationClicked(notification, index) {
      this.$store
        .dispatch(
          'chatNotifications/mark_as_read_notification',
          notification.id
        )
        .then(() => {
          if (notification.data.path) {
            this.$router
              .push({ path: notification.data.path })
              .catch((err) => {})
          }
          this.$nextTick(() => {
            this.$store.commit('app/set_notificationSidebar', false)
          })
        })
    },
    markAll() {
      this.mark_all_as_read('company').then(() => {
        this.appSnackbar('Notification read.')
      })
    }
  }
}
</script>

<style lang="css" scoped>
.v-navigation-drawer.v-navigation-drawer--mini-variant {
  width: 70px;
}
.v-navigation-drawer .sub-group {
  background-color: #80808029;
}
</style>
