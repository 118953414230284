<template>
  <v-navigation-drawer
    v-model="leftSidebar"
    :width="collapseWidth"
    :clipped="smAndDown ? true : clipped"
    :floating="floating"
    :mini-variant.sync="mini"
    :mini-variant-width="miniWidth"
    :permanent="smAndDown ? false : type === 'permanent'"
    :temporary="smAndDown ? true : type === 'temporary'"
    app
    fixed
    class="left-sidebar above-overlay"
    style="margin-top: -1px"
    :stateless="!smAndDown"
  >
    <template v-slot:prepend>
      <v-list-item>
        <v-list-item-avatar size="35" style="border: 2px solid #223a6b">
          <v-img
            contain
            v-if="user.company.company_logo"
            :src="user.company.company_logo"
          ></v-img>
          <v-img
            :lazy-src="settings.loader"
            contain
            v-else
            :src="settings.noCompanyLogo"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="primary--text font-weight-bold">
            {{ user.company.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-list dense transition="slide-x-transition">
      <template v-for="(item, index) in sidebars_filtered">
        <v-list-item
          :value="item"
          class="main-group"
          :key="`main-${index}`"
          v-if="
            (!item.children || item.children.length < 1) &&
            inModuleOrDefault(item)
          "
          :class="isActive(item) ? 'primary' : 'white'"
          @click.stop="action(item)"
        >
          <v-list-item-icon>
            <v-tooltip color="primary" right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="isActive(item) ? '#fff' : 'primary'"
                  v-if="item.icon_type === 'mdi'"
                >
                  {{ item.icon_name }}
                </v-icon>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              :class="isActive(item) ? 'white--text' : 'primary--text'"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
          v-else-if="inModuleOrDefault(item)"
          :key="`main-${index}`"
          no-action
        >
          <template v-slot:prependIcon>
            <v-tooltip color="primary" right>
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  bordered
                  bottom
                  dot
                  color="primary"
                  offset-x="5"
                  offset-y="10"
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary "
                    v-if="item.icon_type === 'mdi'"
                  >
                    {{ item.icon_name }}
                  </v-icon>
                </v-badge>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title class="primary--text">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(subitem, index2) in item.children">
            <v-list-item
              :value="subitem"
              v-if="inModuleOrDefault(subitem)"
              :key="`sub-${index2}`"
              @click.stop="action(subitem)"
              class="sub-group pl-4"
              :class="isActive(subitem) ? 'primary' : 'white'"
            >
              <v-list-item-icon>
                <v-tooltip color="primary" right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      :color="isActive(subitem) ? '#fff' : 'primary'"
                      v-if="subitem.icon_type === 'mdi'"
                    >
                      {{ subitem.icon_name }}
                    </v-icon>
                  </template>
                  <span>{{ subitem.title }}</span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :class="isActive(subitem) ? 'white--text' : 'primary--text'"
                  v-text="subitem.title"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  name: 'LeftSidebar',
  computed: {
    ...mapGetters(['user', 'user_roles']),
    ...mapGetters('app', [
      'sidebars',
      'type',
      'miniWidth',
      'collapseWidth',
      'allowedModules'
    ]),
    sidebars_filtered() {
      return this.sidebars.filter(
        (sb) => _.intersection(this.user_roles, sb.access).length > 0
      )
    },
    leftSidebar: {
      get() {
        return this.$store.getters['app/leftSidebar']
      },
      set(val) {
        this.$store.commit('app/set_leftSidebar', val)
      }
    },
    clipped: {
      get() {
        return this.$store.getters['app/clipped']
      },
      set(val) {
        this.$store.commit('app/set_clipped', val)
      }
    },
    floating: {
      get() {
        return this.$store.getters['app/floating']
      },
      set(val) {
        this.$store.commit('app/set_floating', val)
      }
    },
    mini: {
      get() {
        return this.smAndDown ? false : this.$store.getters['app/mini']
      },
      set(val) {
        this.$store.commit('app/set_mini', val)
      }
    }
  },
  methods: {
    inModuleOrDefault(item) {
      if (!item.module && item.children && item.children.length) {
        return item.children.filter((i) => this.inModuleOrDefault(i)).length
      }
      return this.allowedModules.includes(item.module) || !item.module
    },
    isActive(item) {
      if (!item.route) {
        return false
      }
      return (
        this.$route.name === item.route.name &&
        _.isEqual(this.$route.params, item.route.params)
      )
    },
    action(item) {
      this.$store.commit('app/set_activeSidebar', item)
      this.$router.push(item.route).catch(() => {})
    },
    expandSubItemOnCollapsedSidebar() {
      this.$emit('expand-sub-item-on-collapsed-sidebar', true)
    }
  }
}
</script>

<style lang="scss" scoped>
$navigation-drawer-border-width: 5px !important;
.left-sidebar {
  &.v-navigation-drawer--mini-variant {
    width: 70px;
  }
  &.v-navigation-drawer {
    .v-list-item {
      height: 40px;
    }
    .sub-group {
      &:not(.primary) {
        background-color: #80808029 !important;
      }
    }
  }
}
</style>
