<template>
  <v-toolbar-title class="search-wrapper">
    <v-autocomplete
      height="40"
      class="mx-2 rounded-xl header-main-search overflow-hidden"
      solo
      clear-icon="mdi-close-circle-outline"
      prepend-inner-icon="mdi-magnify"
      append-icon=""
      hide-details="auto"
      placeholder="Search"
      outlined
      clearable
      background-color="transparent"
      dark
      dense
      flat
      style="border: 2px solid #223a6b"
      no-data-text="No result found!"
      v-model="globalSearchbox"
      :search-input.sync="globalSearch"
      :no-filter="true"
      :items.sync="globalSearchResult"
      :loading="isGlobalFetching"
      :open-on-clear="false"
      @click:clear="globalSearchResult = []"
      @click:append-outer="navigate_search"
      :menu-props="menuprops"
      single-line
      item-value="unique_id"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            Search for <strong>Users</strong>, <strong>Projects</strong> etc
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:item="{ item }" class="find">
        <v-list-item
          :key="item.unique_id"
          v-if="item.modelType === 'user'"
          @click="navigate_search(item)"
        >
          <v-list-item-avatar>
            <v-img
              :lazy-src="settings.loader"
              v-if="item.image_url"
              :src="item.image_url"
            />
            <v-icon large v-else>mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              :inner-html.prop="item.fullname | ucwords | truncate(25)"
            />
            <v-list-item-subtitle
              :inner-html.prop="item.job_title | ucwords | truncate(25)"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :key="item.unique_id"
          v-else-if="item.modelType === 'project'"
          @click="navigate_search(item)"
        >
          <v-list-item-avatar>
            <v-avatar :size="40">
              <v-img
                v-if="item.service && item.service.icon"
                :src="item.service.icon"
              />
              <v-icon v-else large>
                mdi-alpha-{{ item.type.charAt(0) }}-circle-outline
              </v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title :inner-html.prop="item.title | truncate(25)" />
            <v-list-item-subtitle :inner-html.prop="item.business_name" />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </v-toolbar-title>
</template>

<script>
import _ from 'lodash'
import request from '@/services/axios_instance'

export default {
  name: 'GlobalSearch',
  data: () => ({
    globalSearchbox: null,
    globalSearchResult: [],
    globalSearch: null,
    isGlobalFetching: false,
    menuprops: {
      closeOnClick: false,
      closeOnContentClick: true,
      disableKeys: true,
      openOnClick: false,
      maxHeight: 320
    },
    timeout: null
  }),
  watch: {
    globalSearch: _.debounce(function (val) {
      if (val && !this.isGlobalFetching && val.length > 2) {
        this.searchNow(val)
      }
    }, 100)
  },
  methods: {
    searchNow(keyword) {
      this.globalSearchResult = []
      this.isGlobalFetching = true
      request
        .get(`api/autocomplete/search?keyword=${keyword}`)
        .then(({ data }) => {
          this.globalSearchResult = data
        })
        .finally(() => (this.isGlobalFetching = false))
    },
    navigate_search(item) {
      if (item.modelType === 'project') {
        this.$router.push(`/dashboard/project/preview/${item.id}`)
      } else if (item.modelType === 'user') {
        this.$router.push({
          name: 'profile',
          params: { username: item.username }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.header-main-search {
  .v-input__icon {
    .v-icon {
      color: #223a6b !important;
    }
  }
  input {
    color: #223a6b !important;
    &::placeholder {
      color: #223a6b !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.search-wrapper {
  max-width: 250px;
}
</style>
