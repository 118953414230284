<template>
  <v-navigation-drawer
    :width="collapseWidth * 1.5"
    right
    class="above-overlay"
    v-model="myMessageSidebar"
    :clipped="true"
    :floating="true"
    :permanent="false"
    :temporary="true"
    app
    overflow
  >
    <template v-slot:prepend>
      <v-list-item class="primary">
        <v-list-item-icon>
          <v-icon @click="close" color="white" large>
            mdi-close-circle-outline
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="title light--text">
            Messages
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-icon>
          <v-icon large color="white"> mdi-message-text </v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-divider></v-divider>
    </template>
    <v-list dense v-if="!isFetching">
      <v-list-item
        :class="{ unread: is_unread(message) }"
        v-for="message in chat"
        :key="message.conversation.id"
        @click="open_chat_box(message)"
      >
        <v-list-item-avatar tile height="42" width="79">
          <Avatars :items="getParticipants(message)" :count="1"></Avatars>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="primary--text">
            <span v-if="is_group_chat(message)">
              {{ message.conversation.name | ucwords }}
            </span>
            <span v-else>
              {{
                getPrivateParticipant(message)
                  ? getPrivateParticipant(message).fullname
                  : '' | ucwords
              }}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle v-if="!is_group_chat(message)">
            <span v-if="is_self_sender(message)">You: </span>
            <span :inner-html.prop="message.body | truncate(20)" />
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>
            <span v-if="is_self_sender(message)">You : </span>
            <span v-else> {{ message.sender.first_name }} : </span>
            <span :inner-html.prop="message.body | truncate(20)" />
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action-text>
          <small class="caption">{{ message.created_at | from_now }}</small>
        </v-list-item-action-text>
      </v-list-item>
    </v-list>
    <Empty
      v-if="!chat.length && !isFetching"
      headline="No messages yet"
      icon="mdi-chat-remove-outline"
    ></Empty>
    <template v-if="isFetching">
      <v-skeleton-loader
        v-for="item in 5"
        :key="item"
        :boilerplate="true"
        type="list-item-avatar-three-line"
        :tile="false"
        class="mx-auto"
      />
    </template>
    <template v-slot:append>
      <v-divider />
      <v-row dense style="height: 40px" class="px-3 mt-3">
        <v-btn
          class="text-none caption"
          dark
          small
          @click="openChat"
          :loading="btnloading"
          color="primary"
        >
          <v-icon left>mdi-wechat</v-icon> See All in Chat
        </v-btn>
        <v-spacer />
        <v-btn
          class="text-none caption"
          dark
          small
          depressed
          @click.stop="markAll"
          :loading="isSubmitting"
          color="primary"
        >
          <v-icon left>mdi-book-open-variant</v-icon> Mark All as Read
        </v-btn>
      </v-row>
      <v-divider></v-divider>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import _ from 'lodash'

export default {
  name: 'MessageSidebar',
  data: () => ({
    btnloading: false
  }),
  created() {
    this.$store.watch(
      (state) => {
        return this.$store.getters['app/messageSidebar']
      },
      (val) => {
        if (val) {
          this.$store.dispatch('chatNotifications/fetch_chat')
        }
      },
      {
        deep: true
      }
    )
  },
  computed: {
    ...mapGetters(['user', 'user_roles']),
    ...mapGetters('onlineUsers', ['all_users']),
    ...mapGetters('app', ['collapseWidth', 'messageSidebar']),
    ...mapGetters('chatNotifications', ['chat', 'isFetching', 'isSubmitting']),
    myMessageSidebar: {
      get() {
        return this.messageSidebar
      },
      set(val) {
        this.btnloading = false
        this.$store.commit('app/set_messageSidebar', val)
      }
    }
  },
  methods: {
    ...mapActions('chatNotifications', ['fetch_more_chat', 'mark_all_as_read']),
    close() {
      this.$store.commit('app/set_messageSidebar', false)
    },
    openChat() {
      this.$router.push({ name: 'chat' }).catch(() => {})
      this.$nextTick(() => this.close())
    },
    is_unread(message) {
      if (message.hasOwnProperty('notification') && message.notification)
        return message.notification.is_seen === 0
      return message.sender.id !== this.user.id
    },
    is_group_chat(message) {
      return (
        message.conversation.type &&
        ['group', 'team', 'client'].includes(message.conversation.type)
      )
    },
    is_self_sender(message) {
      return message.sender.id === this.user.id
    },
    is_user_online(id) {
      const user = this.all_users.find((user) => user.id === id)
      if (!user) return false
      return user.is_online
    },
    open_chat_box(message) {
      let route_name = ['team', 'client'].includes(message.conversation.type)
        ? 'project-chats'
        : 'chat'
      this.$router
        .push({
          name: route_name,
          params: {
            id: message.conversation.project_id,
            conversation_id: message.conversation.id
          }
        })
        .catch(() => {})
      this.$nextTick(() => {
        this.$store.commit('app/set_messageSidebar', false)
      })
    },
    getPrivateParticipant(message) {
      let i = message.conversation.participants.filter(
        (i) => i.id !== this.user.id
      )
      return i.length ? i[0] : null
    },
    getParticipants(message) {
      if (['group', 'team', 'client'].includes(message.conversation.type)) {
        return message.conversation.participants
      } else {
        return message.conversation.participants.filter(
          (i) => i.id !== this.user.id
        )
      }
    },
    markAll() {
      this.mark_all_as_read('chat').then(() => {
        this.appSnackbar('Message notification read.')
      })
    }
  }
}
</script>

<style lang="css" scoped>
.v-navigation-drawer.v-navigation-drawer--mini-variant {
  width: 70px;
}
.v-navigation-drawer .sub-group {
  background-color: #80808029;
}
</style>
