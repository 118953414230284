<template>
  <v-tooltip color="primary" bottom>
    <template v-slot:activator="{ on: tooltip }">
      <v-hover v-slot="{ hover }">
        <v-badge
          v-on="{ ...tooltip }"
          color="error"
          overlap
          offset-x="15"
          offset-y="15"
          dot
          :value="total_counts > 0"
        >
          <v-btn
            width="40"
            tile
            text
            icon
            v-on="{ ...tooltip }"
            @click.stop="toggleNotificationSidebar"
          >
            <v-icon :color="hover ? 'success' : 'primary'">
              {{ total_counts > 0 ? 'mdi-bell-ring' : 'mdi-bell' }}
            </v-icon>
          </v-btn>
        </v-badge>
      </v-hover>
    </template>
    <span>Notifications</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'HeaderNotifications',
  data: () => ({
    messages: 44
  }),
  computed: {
    total_counts() {
      return this.$store.getters['chatNotifications/notification_counts']
    }
  },
  methods: {
    toggleNotificationSidebar() {
      this.$store.commit('app/set_notificationSidebar', true)
    }
  }
}
</script>

<style></style>
